import React from "react";
import { Table } from 'ka-table';
import { SortingMode } from 'ka-table/enums';

import CustomIcon from "components/Icons";


const MlsCustomerTable = ({ customerModalValue }) => {
    return (
        <div className="viewCustomerTable">
            <Table {...customerModalValue}
                columnReordering={true}
                sortingMode={SortingMode.Single}
                noData={{
                    text: 'No Data Found',
                }}
                childComponents={{
                    elementAttributes: (props) => {
                        return {
                            style: {
                                ...props.column.style,
                            },
                        };
                    },
                    cellText: {
                        content: (props) => {
                            switch (props.column.key) {
                                case 'ClientBAY':
                                case 'SocialBAY':
                                    return props.rowData[props.column.key] === "true" ? <div key={2}>
                                        <CustomIcon className="dragColumn me-1" icon="tick" size={20} color="green" />
                                    </div> : <div key={3}>
                                        <CustomIcon className="dragColumn me-1" icon="wrong" size={20} color="red" />
                                    </div>

                            }
                        }
                    },

                }}
            />

        </div>
    )
}

export default MlsCustomerTable;