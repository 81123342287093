import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    mlsStatDetail: {},
    mlsLists: [],
    modalHeader: '',
    copyDetailsText: '',
    selectedAction: {},
    mainFilterData: {},
    filterCount: 0,
    toastContainer:{
        text:'',
        show:false,
        variant:''
    }

};
export const mlsSlice = createSlice({
    name: 'mls',
    initialState,
    reducers: {
        setMlsStatistics: (state, action) => {
            state.mlsStatDetail = action.payload
        },
        setMlsLists: (state, action) => {
            state.mlsLists = action.payload
        },
        setModalHeader: (state, action) => {
            state.modalHeader = action.payload
        },
        setCopyDetailsText: (state, action) => {
            state.copyDetailsText = action.payload
        },
        setSelectedAction: (state, action) => {
            state.selectedAction = action.payload
        },
        setMainFilterData: (state, action) => {
            state.mainFilterData = action.payload
        },
        setFilterCount: (state, action) => {
            state.filterCount = action.payload
        },
        setToastContainer: (state, action) => {
            state.toastContainer = action.payload
        },
        
    }
});

// Action creators are generated for each case reducer function
export const { setMlsStatistics, setMlsLists, setModalHeader, setCopyDetailsText, setSelectedAction, setMainFilterData,setFilterCount,setToastContainer } = mlsSlice.actions;

export const getMlsState = (state) => state.mls;

export default mlsSlice.reducer;

