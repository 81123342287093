import { Card, Col, Spinner } from "react-bootstrap"
import { FaCheck } from "react-icons/fa";
import { BsX } from "react-icons/bs";

import BayToolTip from '@back-at-you-inc/bay-ui/dist/BayToolTip';

import CustomIcon from "components/Icons"

export const DomainCard = ({ displayLoop }) => {

    return (
        <>{
            Object.keys(displayLoop).length > 0 ? <Col className="my-2 ">
                <Card>
                    <Card.Body className="card_body p-0 border-0">
                        {Object.keys(displayLoop).length > 0 && Object.keys(displayLoop?.statusesOfSteps).map((item, index) => {
                            return (
                                <div className="border-bottom" key={index}>
                                    <Col xl={11} className="w-100" >
                                        <div className="p-3 list-group-item d-flex justify-content-between align-items-center p-2 text text-gray" >
                                            {item}
                                            <span xl={1}>{
                                                <BayToolTip
                                                    placement='top'
                                                    overlay={displayLoop?.statusesOfSteps[item]?.result?.isErr ? "Failed" : displayLoop?.statusesOfSteps[item]?.isOk ? "Success" : "Pending"}
                                                >
                                                    {displayLoop?.statusesOfSteps[item]?.result?.isErr ? <BsX size={25} color="red" /> : displayLoop?.statusesOfSteps[item]?.isOk ? <FaCheck color="green" /> : <CustomIcon icon="clock" color="lightgray" />}
                                                </BayToolTip>
                                            }</span>
                                        </div>
                                    </Col>
                                </div>
                            )
                        })}
                    </Card.Body>
                </Card>
            </Col> : <div className="d-flex justify-content-center">  <Spinner /></div>
        }</>
    )
}