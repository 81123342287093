import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import BayModal from '@back-at-you-inc/bay-ui/dist/BayModal';

import MlsCustomerTable from "./viewCustomersTable";
import { MLS_CUSTOMER_MODAL_COLS } from "components/Constants/mlsConstants";

const MlsCustomerViewModal = ({ viewOpenModal, setViewOpenModal, modalHeader, customerModalValue }) => {
    const [tesss, setTess] = useState(null)
    useEffect(() => {
        // constructed the new array to display the table by combining the names of the customer
        const associatedCustomersObj = {};
        if (customerModalValue.data?.length) {
            let data = customerModalValue.data[0]
            for (const target in data.targets) {
                const associatedCustomers = data.targets[target].associatedCustomers;
                if (associatedCustomers) {
                    associatedCustomers.forEach(customer => {
                        const name = customer.name;
                        const isClientBAY = data?.targets?.ClientBAY && data?.targets?.ClientBAY?.associatedCustomers?.some(c => c?.name === name);
                        const isSocialBAY = data?.targets?.SocialBAY && data?.targets?.SocialBAY?.associatedCustomers?.some(c => c?.name === name);
                        associatedCustomersObj[name] = {
                            ClientBAY: isClientBAY,
                            SocialBAY: isSocialBAY,
                        };
                    });
                }
            }
        }
        const createdData = Object.entries(associatedCustomersObj).map(([Customer, { ClientBAY, SocialBAY }]) => ({
            Customer,
            ClientBAY,
            SocialBAY,
        }));
        setTess(createdData)
    }, [customerModalValue])

    return (
        <BayModal
            show={viewOpenModal}
            size={'lg'}
            onHideFunc={() => setViewOpenModal(false)}
            closeButton={true}
            title={`${modalHeader} - ${customerModalValue?.data[0]?._id}`}
            hideFooter={true}
            scrollable={true}
        >
            <Row>
                <Col xs={12}>
                    <div>
                        <MlsCustomerTable
                            customerModalValue={{ ...customerModalValue, data: tesss, columns: MLS_CUSTOMER_MODAL_COLS?.columns }}
                        />
                    </div>
                </Col>
            </Row>
        </BayModal>
    )
}

export default MlsCustomerViewModal;