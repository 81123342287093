import { configureStore } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux'

import { getSessionState, setSessionState } from '../modules/utils';

import { getNavState, setNavState, resetNavState } from './slices/navSlice';
import { getNotificationState, setNotificationState, resetNotificationState } from './slices/notificationSlice';
import { getUserState, setUserState, resetUserState } from './slices/userSlice';
import { getActingAsState, setActingAsState, resetActingAsState } from './slices/actingAsSlice';
import { getMlsState, setMlsStatistics, setMlsLists, setModalHeader, setCopyDetailsText, setSelectedAction, setMainFilterData,setFilterCount, setToastContainer } from './slices/mlsSlice';

import reducers from './reducers';
import { getHomeState,setMainStats } from './slices/homeSlice';

const preloadedState = getSessionState('globalState');

const store = configureStore({
	devTools: true,
	reducer: reducers,
	preloadedState: preloadedState
});

store.subscribe(() => {
	setSessionState('globalState', store.getState());
});

const globalState = {
	//redux
	useDispatch
	, store
	//nav
	, useNavState: () => {
		let navState = useSelector(getNavState);

		return navState;
	}
	, setNavState
	, resetNavState

	//notifications
	, useNotificationState: () => {
		let notificationState = useSelector(getNotificationState);

		return notificationState;
	}
	, setNotificationState
	, resetNotificationState

	//user
	, useUserState: () => {
		let userState = useSelector(getUserState);

		return userState;
	}
	, setUserState
	, resetUserState

	//Acting As
	, useActingAsState: () => {
		let actingAsState = useSelector(getActingAsState);

		return actingAsState;
	}
	, setActingAsState
	, resetActingAsState
	//Home
	, useHomeState: ()=>{
		let actingAsState = useSelector(getHomeState);

		return actingAsState;
	}
	,setMainStats

	//MLS
	, useMLsState: () => {
		let mlsState = useSelector(getMlsState);

		return mlsState;
	},
	setMlsStatistics,
	setMlsLists,
	setModalHeader,
	setCopyDetailsText,
	setSelectedAction,
	setMainFilterData,
	setFilterCount,
	setToastContainer
}

export default globalState;