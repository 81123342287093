import axios from 'axios';
import { TOOLBOX_API_URL } from '../../lib/environment';

export default {

    getSiteType: (siteType) => {
        return new Promise((resolve, reject) => {
            axios({
                url: `${TOOLBOX_API_URL}/harnesses.domainSetup.${siteType}/NEW`,
                method: 'GET',
                cache: 'no-cache',
                signal: AbortSignal.timeout(180000)
            })
                .then((resp) => {
                    resolve(resp.data.result);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getIdInfoProgress: (siteID) => {
        return new Promise((resolve, reject) => {
            axios({

                url: `${TOOLBOX_API_URL}/harnesses/${siteID}/progress`,
                method: 'GET',
                cache: 'no-cache',
                signal: AbortSignal.timeout(180000)
            })
                .then((resp) => {
                    resolve(resp.data.result);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    domain: (data, siteType) => {
        return new Promise((resolve, reject) => {
            const controller = new AbortController();

            let timeout = setTimeout(() => {
                controller.abort();
            }, 180000);

            axios({
                url: `${TOOLBOX_API_URL}/harnesses.domainSetup.${siteType}?raze=t&runAsync=t`,
                method: 'POST',
                cache: 'no-cache',
                data: { "domain": data },
                signal: controller.signal
            })
                .then((resp) => {
                    clearTimeout(timeout);
                    resolve(resp.data);
                })
                .catch((err) => {
                    clearTimeout(timeout);
                    reject(err);
                });
        });
    },
}