import React from 'react'
import { CloseButton } from 'react-bootstrap';
import BayFilterDrawer from '@back-at-you-inc/bay-ui/dist/BayFilterDrawer';

import ServiceFilterFormCommon from 'components/pages/commonFilterForm';
import globalState from 'state/global';

export default function MlsDrawer({ showFilterDrawer, setShowFilterDrawer, CloseDrawer, setFilterDrawerData, clearDrawerData }) {
    const mlsStateValue = globalState.useMLsState();
    const { mainFilterData, mlsLists } = mlsStateValue
    const searchDrawerFilterArr = [
        { title: "Platform Feed ID", name: "_id", optionsArr: mlsLists, defaultValue: mainFilterData, isMulti: true, isClearable: true },
        { title: "Data Server", name: "dataServer", optionsArr: mlsLists, defaultValue: mainFilterData, isMulti: true, isClearable: true },
        { title: "DM Server", name: "dmServer", optionsArr: mlsLists, defaultValue: mainFilterData, isMulti: true, isClearable: true },
        { title: "Server/Bucket", name: "serverBucket", optionsArr: mlsLists, defaultValue: mainFilterData, isMulti: true, isClearable: true },
    ]

    const FilterDrawerHeader = () => {
        return (
            <div className="d-flex flex-column w-100 filter-drawer-header">
                <div className="d-flex align-item-center justify-content-between">
                    <div className="d-flex align-items-center">
                        <h2 className="filter-header">Filter</h2>
                    </div>
                    <div className="d-flex align-items-center">
                        <CloseButton
                            className="me-0"
                            onClick={CloseDrawer}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const handleApplyDrawerFilter = () => {
        const { _id, dataServer, dmServer, serverBucket } = mainFilterData
        setFilterDrawerData({
            _id: _id?.length ? _id.map(val => val?.value?.toLowerCase()) : [],
            dataServer: dataServer?.length ? dataServer.map(val => val?.value?.toLowerCase()) : [],
            dmServer: dmServer?.length ? dmServer.map(val => val?.value?.toLowerCase()) : [],
            serverBucket: serverBucket?.length ? serverBucket.map(val => val?.value?.toLowerCase()) : [],
        })
    }

    const clearDrawerFilter = () => {
        clearDrawerData()
        CloseDrawer()
    }

    return (
        <BayFilterDrawer
            showDrawer={showFilterDrawer}
            setShowFilterDrawer={setShowFilterDrawer}
            handleCloseDrawer={CloseDrawer}
            headerChildren={
                <FilterDrawerHeader />
            }
        >
            <ServiceFilterFormCommon
                handleApplyDrawerFilter={handleApplyDrawerFilter}
                clearDrawerFilter={clearDrawerFilter}
                fieldArr={searchDrawerFilterArr}
                formId={'mls-table'}
            />
        </BayFilterDrawer>
    )
}
