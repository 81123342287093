
import { DataType, SortDirection } from 'ka-table/enums';

import CustomIcon from "components/Icons";

export const MLS_QUICK_ACTIONS_ARR = [
    { value: "viewConfiguration", label: "View Configuration", icon: <CustomIcon icon="copy" size={20} /> },
    { value: "generateTrestleReport", label: "Generate Trestle Report", icon: <CustomIcon icon="edit" size={20} /> },
    { value: "copy", label: "Duplicate Config", icon: <CustomIcon icon="edit" size={20} /> },
]
export const MLS_HEADER = 'Here you can review the different feed services we integrate with, the platforms they are connected to and some statistics. You are able to modify each feed and utilize some tools through this interface as well.'
export const MLS_TABLEPROPS_INIT = {
    columns: [
        { key: '_id', style: { width: 300, zIndex: 10, position: 'sticky', left: 0, top: 0, paddingRight: 0 }, title: 'Platform Feed ID', isSortable: true, dataType: DataType.String, sortDirection: SortDirection.Ascend },
        { key: 'name', style: { width: 300, }, title: 'Name', dataType: DataType.String, isSortable: true },
        { key: 'iconUrl', style: { width: 250 }, title: 'Logo', dataType: DataType.String, isSortable: true },
        { key: 'ClientBAY', style: { width: 150, }, title: 'ClientBAY', dataType: DataType.String, isSortable: true },
        { key: 'SocialBAY', style: { width: 150 }, title: 'SocialBAY', dataType: DataType.String },
        { key: 'dtLastDownload', style: { width: 200 }, title: 'Last Download', dataType: DataType.String, isSortable: true },
        { key: 'dtLastDownloadFull', style: { width: 200 }, title: 'Last Full Download', dataType: DataType.String, isSortable: true },
        { key: 'companyIds', style: { width: 150 }, title: 'Customers', dataType: DataType.String, isSortable: true },
        { key: 'dataServer', style: { width: 200 }, title: 'Data Server', dataType: DataType.String, isSortable: true },
        { key: 'dmServer', style: { width: 200 }, title: 'DM Server', dataType: DataType.String, isSortable: true },
    ],
    data: [],
    rowKeyField: '_id',
    selectedRows: []
}

export const MLS_STAT_TITLES = {
    nTot: "FEED COUNT",
    nHavingSoldStatus: "FEED COUNT WITH SOLD",
    nConnectedToSocialBay: "SOCIALBAY FEED COUNT",
    nConnectedToClientBay: "CLIENTBAY FEED COUNT",
}
export const DRAWER_FILTER_KEYS = ['_id', 'dataServer', 'dmServer', 'serverBucket']

export const MLS_CUSTOMER_MODAL_COLS = {
     
    columns: [
        { key: 'Customer', style: { width: 200 }, title: 'Customer', dataType: DataType.String },
        { key: 'ClientBAY', style: { width: 200 }, title: 'ClientBAY', dataType: DataType.String },
        { key: 'SocialBAY', style: { width: 200 }, title: 'SocialBAY', dataType: DataType.String },
    ],
    data: [],
    rowKeyField: '_id',
    selectedRows: []
}