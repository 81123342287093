import React, { useEffect, useState } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import BayModal from '@back-at-you-inc/bay-ui/dist/BayModal';

import CustomInput from 'components/pages/formFields';
import globalState from 'state/global';
import api from 'modules/api';
import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { okaidiaInit } from '@uiw/codemirror-theme-okaidia';

export default function MlsModalContent({ enableActionModal, setEnableActionModal }) {
    const globalDispatch = globalState.useDispatch();
    const mlsStateValue = globalState.useMLsState();
    const { selectedAction, modalHeader } = mlsStateValue

    const [copyText, setcopyText] = useState('');
    const [isModalLoading, setIsModalLoading] = useState(false);
    const [isScrollable, setIsScrollable] = useState(false);
    const [isenableValidate, setIsenableValidate] = useState(false);
    const handleModalText = () => {
        let modalText = ''

        switch (selectedAction?.eventType) {
            case 'copy':
                modalText = `Duplicate Feed Mongo Configuration from ${selectedAction?.wholeRow?._id}`
                globalDispatch(globalState.setModalHeader(modalText));
                return <CustomInput
                    isRequired={true}
                    validate={isenableValidate}
                    fieldLabel=" New Platform Feed ID"
                    showIcon={false}
                    setSearchValue={(e) => { setcopyText(e) }}
                />
            case 'viewConfiguration':
                modalText = `View Configuration`
                globalDispatch(globalState.setModalHeader(modalText));
                return <MLSViewConfigurationModal setIsModalLoading={setIsModalLoading} setIsScrollable={setIsScrollable} />
            case 'generateTrestleReport':
                modalText = `Generate Trestle Report`
                globalDispatch(globalState.setModalHeader(modalText));
                return
            default:
                break;
        }
        globalDispatch(globalState.setModalHeader(modalText));
    }

    const handleModalSubmit = () => {
        if (selectedAction.eventType === "generateTrestleReport") {
            setIsModalLoading(true);
            api.mls.trestleReport(selectedAction?.wholeRow?._id).then((res) => {
                globalDispatch(globalState.setToastContainer({variant:'success',text:res?.message,show:true}))
            })
                .catch((err) => {
                globalDispatch(globalState.setToastContainer({variant:'danger',text:err?.message,show:true}))
                }).finally(() => {
                    setEnableActionModal(false);
                    setIsModalLoading(false)
                });
        } else {
            if (copyText?.trim('')?.length) {
                setIsModalLoading(true)
                let obj = {
                    _id: selectedAction?.wholeRow?._id ?? '',
                    newMlsId: copyText
                }
                api.mls.insertDuplicateMls(obj).then((res) => {
                    globalDispatch(globalState.setToastContainer({variant:'success',text:res?.message,show:true}))
                })
                    .catch((err) => {
                        globalDispatch(globalState.setToastContainer({variant:'danger',text:err?.message,show:true}))
                    }).finally(() => {
                        setEnableActionModal(false);
                        setIsModalLoading(false)
                    });
            } else {
                setIsenableValidate(true)
            }
        }
    }
    return (
        <>
            {enableActionModal && <BayModal
                size={'lg'}
                disabled={isModalLoading}
                buttonText={
                    "Submit"
                }
                cancelText="Close"
                submitFunc={() => handleModalSubmit()}
                scrollable={isScrollable}
                onHideFunc={() => {
                    setEnableActionModal(false);
                    setIsenableValidate(false)
                }}
                hideSubmit={selectedAction?.eventType === 'viewConfiguration'}
                closeButton={true}
                title={modalHeader}
                hideHeader={!modalHeader?.length}
                show={enableActionModal}
            
            >
                <Row className='gy-2'>
                    <Col xs={12}>
                        {isModalLoading && <div className='h-100 w-100 d-flex justify-content-center position-absolute'>
                            <Spinner animation="border" role="status"></Spinner>
                        </div>}
                        {handleModalText()}
                    </Col>
                </Row>
            </BayModal>
            }</>

    )
}
export const MLSViewConfigurationModal = ({ setIsModalLoading, setIsScrollable }) => {
    const mlsStateValue = globalState.useMLsState();
    const { selectedAction } = mlsStateValue
    const [jsonResponse, setJsonResponse] = useState('')

    useEffect(() => {
        setIsScrollable(false)
        setIsModalLoading(true)
        api.mls.getViewConfig(null, selectedAction?.wholeRow?._id).then((res) => {
            setJsonResponse(res)
        })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setIsModalLoading(false)
                setIsScrollable(true)
            });
    }, [])

    return (
        <>
            {typeof jsonResponse === 'object' && <CodeMirror theme={okaidiaInit({
                settings: {
                    caret: '#c6c6c6',
                    fontFamily: 'monospace',
                }
            })} value={JSON.stringify(jsonResponse, null, 2)}
                minHeight="200px"
                minWidth="200px"
                editable={false}
                extensions={[javascript({ jsx: true })]} />}
        </>
    )
}