import React from "react";
import { Container, Row, Col } from 'react-bootstrap';

import BayHeader from '@back-at-you-inc/bay-ui/dist/BayHeader';

import { MainNav } from "components/partials/navigation/MainNav";
import { DOMAIN_HEADER } from "components/Constants/domainConstants";
import { PageContainer } from '../partials/page/PageContainer';
import { DomainMainContent } from "./domain/UIComponents/domainContent";

export const Domain = () => {

    return (
        <div className="domain-page">
            <PageContainer id="index">
                <Row className='m-0' >
                    <Col sm={2} xs={12} className="bg-gray-200" style={{ minHeight: 'calc(100vh - 60px)' }}>
                        <MainNav />
                    </Col>
                    <Col sm={10} xs={12} className="col-12 p-0 bg-gray-100" style={{ minHeight: 'calc(100vh - 60px)' }}>
                        <Container className="py-3" fluid={true}>
                            <BayHeader
                                headerContent={DOMAIN_HEADER}
                                headerTitle={'Domain Setup'}
                            />
                        </Container>
                        <div className="pt-2">
                            <DomainMainContent />
                        </div>
                    </Col>
                </Row>
            </PageContainer>
        </div>
    )

}