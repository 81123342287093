import { useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';

import { SERVICE_QUICK_ACTIONS } from 'components/Constants/serviceConstants';

export default function BulkActions({
    tableProps = {},
    setSelectedAction,
    setEnableActionModal
}) {

    const bulkActionsArr = useMemo(() => {
        if (tableProps?.selectedRows?.length > 1) {
            return SERVICE_QUICK_ACTIONS
        } else {
            if (tableProps?.selectedRows[0]) {
                let filteredData = tableProps?.data?.filter((item) => item?._id === tableProps?.selectedRows[0])
                if ((filteredData[0]?.status?.toLowerCase() === "running") || filteredData[0]?.status.toLowerCase() === "stopped") {
                    return SERVICE_QUICK_ACTIONS.filter((item) => filteredData[0]?.status?.toLowerCase() === "running" ? item?.value !== "Start" : item?.value !== "Stop")
                }
                return SERVICE_QUICK_ACTIONS
            }
        }
    }, [tableProps])
    return (
        <div>
            <div className="row g-0 justify-content-between w-100">
                <div className="d-flex align-items-center justify-content-between justify-content-sm-start">
                    <div className="bay-selection-counter mx-2 me-sm-3 pb-1">
                        {tableProps?.selectedRows?.length}
                    </div>
                    <div className='d-flex flex-wrap'>
                        {bulkActionsArr?.map((item, index) => {
                            return (
                                <div key={index} className="d-none d-sm-block pb-1">
                                    <Button
                                        disabled={!tableProps?.selectedRows?.length}
                                        key={index}
                                        variant="outline-secondary"
                                        className="filter-btn me-1 me-sm-2"
                                        onClick={() => {
                                            setSelectedAction({ eventType: item?.value, type: 'bulk' });
                                            setEnableActionModal(true)
                                        }}
                                    >
                                        {item.icon} <span className='ps-1'>{item?.label}</span>
                                    </Button>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
