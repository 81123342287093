import React from 'react'
import BaySpinner from '@back-at-you-inc/bay-ui/dist/BaySpinner';

const MobileGridComponent = ({ filteredTableProps, loading }) => {
    return (
        <div className="d-block d-sm-none row g-0">
            <div className={`${loading ? 'bay-placeholder-overlay' : ''}`}>
                {loading && (filteredTableProps && filteredTableProps?.length) && <BaySpinner title={'Loading'} className="w-100 h-100 position-absolute" />}
                {
                    filteredTableProps?.length ? filteredTableProps?.map((mlsDetails, index) => {
                        return (
                            <div
                                className="col xs-data-grid ps-2 cursor-pointer"
                                key={index}>
                                <div className="d-flex align-items-center w-100">
                                    <div className='d-flex'
                                        style={{ width: '88%' }} // Width speicfically set for 560 to 320 mis-alignment,Dropdown event click and purpose of Dropdown position
                                    >
                                        <div className="content ms-3 w-75">
                                            <div className="service-name text-dark text-truncate mx-0 text-capitalize"> {mlsDetails.mls} </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    }) : <span className='d-flex justify-content-center'>No Data Found</span>
                }
            </div>
        </div>
    )
}

export default MobileGridComponent