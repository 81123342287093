import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { FiHome, FiLayout, FiLock, FiUser, FiUsers, FiGlobe } from 'react-icons/fi';
import { HiMiniWrenchScrewdriver } from 'react-icons/hi2';

import { getAccess } from "../../../modules/access";

import globalState from '../../../state/global';

export const MainNav = (props) => {
	// const userState = globalState.useUserState();
	const actingAsState = globalState.useActingAsState();

	let navigate = useNavigate();

	const getClass = (pathName)=>{
		if(window.location.pathname === pathName){
			return 'text-dark text-decoration-none fw-bolder';
		}else{
			return 'text-dark text-decoration-none';
		}
	}

	const menuItems = (getAccess().isSuperAdmin || getAccess().isSystemAdmin) ? [
		{ title: 'Admin', icon: <FiLock />, link: "/admin", "subMenuItems": [] },
		{ title: 'Users', icon: <FiUser />, link: "/Users", "subMenuItems": [] },
		{ title: 'Customers', icon: <FiUsers />, link: "/customers", "subMenuItems": [] },

	] :
		[
			{ title: 'Home', icon: <FiHome />, link: "/", "subMenuItems": [] },
			{ title: 'Feeds', icon: <FiLayout />, link: "/feeds", "subMenuItems": [] },
			{ title: 'Services', icon: <FiGlobe />, link: "/services", "subMenuItems": [] },
			{ title: 'Domain', icon: <HiMiniWrenchScrewdriver />, link: "/domain-setup", "subMenuItems": [] }
		]

	return (
		<ul className="list-unstyled my-3">
			{menuItems?.map((item, index)=>{
				return(
					<li className="mb-1" key={index}>
						<Button variant="link" className={getClass(item.link)} onClick={() => { navigate(item.link) }}>
							<span xl={1} className='d-flex align-items-center gap-2' >{item.icon} {item.title}</span>
						</Button>
					</li>
				)
			})}
		</ul>
	);
};
