import { DataType, SortDirection } from 'ka-table/enums';
import CustomIcon from "components/Icons";

export const TOGGLE_LAYOUT = 'TOGGLE_LAYOUT';

export const SERVICES_GRID_FILTER = ['name', 'health', 'service name', 'status', 'action status'];

export const SERVICE_TABLE_PROPS_INIT = {
    columns: [
        { key: "selection-cell", isEditable: false, style: { width: 50, zIndex: 10, position: 'sticky', left: 0, top: 0, height: 54, borderRight: 0 } },
        { key: 'name', style: { width: 250, zIndex: 10, position: 'sticky', left: 50, top: 0, paddingRight: 0 }, title: 'NAME', isSortable: true, dataType: DataType.String, sortDirection: SortDirection.Ascend },
        { key: 'health', style: { width: 150, }, title: 'HEALTH', dataType: DataType.String, isSortable: true },
        { key: 'status', style: { width: 150 }, title: 'STATUS', dataType: DataType.String, isSortable: true },
        { key: 'action', style: { width: 150 }, title: 'ACTION', dataType: DataType.String },
        { key: 'actionStatus', style: { width: 150 }, title: 'ACTION STATUS', dataType: DataType.String, isSortable: true },
        { key: 'serviceName', style: { width: 250 }, title: 'SERVICE NAME', dataType: DataType.String, isSortable: true },
        { key: 'host', style: { width: 300 }, title: 'HOST', dataType: DataType.String, isSortable: true },
        { key: 'image', style: { width: 300 }, title: 'IMAGE', dataType: DataType.String, isSortable: true },
    ],
    data: [],
    rowKeyField: '_id',
    selectedRows: []
}

export const SERVICE_QUICK_ACTIONS = [
    { value: "Start", label: "Start", icon: <CustomIcon icon="start" size={20} /> },
    { value: "Stop", label: "Stop", icon: <CustomIcon icon="stop" size={20} /> },
    { value: "Rebuild", label: "Rebuild", icon: <CustomIcon icon="refresh" size={20} /> },
    { value: "Move", label: "Move", icon: <CustomIcon icon="truck" size={20} /> },
    { value: "Update", label: "Update", icon: <CustomIcon icon="imageFrame" size={20} /> },
    { value: "Remove", label: "Remove", icon: <CustomIcon icon="trash" size={20} /> }
]

export const SERVICE_STATS_TITLES = {
    totalHostCount: "TOTAL HOSTS",
    totalServiceCount: "TOTAL SERVICES",
    totalServiceDownCount: "SERVICES (DOWN)",
    totalServiceUpCount: "SERVICES (UP)",
}