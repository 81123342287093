import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Form, Button, FloatingLabel, Alert } from 'react-bootstrap';
import Cookies from 'js-cookie';
import { isNil } from 'lodash';
import { Loading } from '../partials/loading/Loading';

import globalState from '../../state/global';

import api from '../../modules/api';

export const Login = () => {
	const globalDispatch = globalState.useDispatch();

	const [loginInfo, setLoginInfo] = useState({
		email: ''
		, password: ''
	});

	const [isProcessing, setIsProcessing] = useState(false);
	const [loginError, setLoginError] = useState();

	const handleChange = (elm) => {
		let fieldName = elm.target.name;
		let fieldValue = elm.target.value;

		loginInfo[fieldName] = fieldValue;

		setLoginInfo(loginInfo);
	}

	const handleSubmit = (e) => {
		e.preventDefault();

		let isFormValid = true;

		let form = document.getElementById('loginForm');

		for (let field of form.elements) {
			let isFieldValid = validateField(field);

			if (!isFieldValid) {
				isFormValid = false
			}
		};

		if (isFormValid) {
			handleLogin();
		}
	}

	const validateField = (field) => {
		if (!field.validity.valid) {
			field.classList.add('is-invalid');
			return false;
		} else {
			field.classList.remove('is-invalid');
			return true;
		}
	}

	const handleLogin = () => {
		setIsProcessing(true);

		api.login.login(loginInfo)
			.then(resp => {
				if (resp.status === 0) {
					globalDispatch(globalState.setUserState(resp.result.session?.user));
					globalDispatch(globalState.setActingAsState(resp.result?.session?.user));

					setLoginError();
				} else {
					setLoginError(resp.message);
				}
			})
			.catch(e => {
				setLoginError(e.message);
			})
			.finally(() => {
				setIsProcessing(false);
			});
	}

	return (
		<Container className="login px-0" fluid id="login_page">
			<Row>
				<Col sm={8} className="px-0 login-bg">
				<img width={'100%'} height={"100%"}src="/assets/img/login-background.jpg" alt="Toolbox-Login Logo" />
				</Col>
				<Col sm={4} className="px-0 vh-100 bg-white d-flex align-items-center justify-content-center bg-white py-5">
					<Form className="px-5" onChange={handleChange} id="loginForm" onSubmit={(e) => { e.preventDefault(); return false; }}>
						<Row>
							<Col className="mb-5">
								<img className="d-block w-50 mx-auto" src="/assets/img/toolbox-logo.png" alt="DesignBAY Logo" />
							</Col>
						</Row>
						{!isNil(loginError) && (
							<Row>
								<Col className="text-center mb-3">
									<Alert variant="danger">{loginError}</Alert>
								</Col>
							</Row>
						)}
						<Row>
							<Col className="mb-3">
								<Form.Group className="mb-3" id="loginForm-email-group">
									<FloatingLabel
										id="loginForm-email-floatingLabel"
										label="Email"
										className="mb-3">

										<Form.Control id="loginForm-email" type="email" placeholder="Enter email" size="lg" name="email" autoComplete="email" required />
									</FloatingLabel>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col className="mb-3">
								<Form.Group id="loginForm-password-group">
									<FloatingLabel
										id="loginForm-password-floatingLabel"
										label="Password"
										className="mb-3">

										<Form.Control id="loginForm-password" type="password" placeholder="Password" size="lg" name="password" autoComplete="current-password" required />
									</FloatingLabel>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col className="text-end mt-3">
								{isProcessing
									? <Loading message="Logging In" />
									: (
										<Button id="loginForm-login" variant="primary" type="submit" size="lg" name="login" onClick={handleSubmit}>
											Login
										</Button>
									)
								}
							</Col>
						</Row>

						<Form.Control id="loginForm-userId" type="hidden" size="lg" name="userId" />
					</Form>
				</Col>
			</Row>
		</Container>
	);
};
