import React from "react";
import { Table } from 'ka-table';
import { SortingMode, SortDirection } from 'ka-table/enums';
import BaySpinner from "@back-at-you-inc/bay-ui/dist/BaySpinner";

import CustomIcon from "components/Icons";
import MlsQuickActions from "./mlsQuickActions";

const MLSTable = ({
	tableProps,
	dispatch,
	setEnableActionModal,
	listLoad,
	handleActionClicked,
	setViewOpenModal,
	customerModalValue,
	setCustomerModalValue
}) => {

	return (
		<div className="data-grid mt-1">
			<div className="d-sm-block">
				<div>
					<div className="bay-data-grid mt-3">
						{<Table {...tableProps}
							dispatch={dispatch}
							loading={{
								enabled: listLoad,
								text: 'Loading data...',
							}}
							sortingMode={SortingMode.Single}
							noData={{
								text: tableProps?.isDataSet && !tableProps?.data?.length && 'No Data Found',
							}}
							columnReordering={true}
							childComponents={{

								elementAttributes: (props) => {
									return {
										style: {
											...props.column.style,
										},
									};
								},
								headCellContent: {
									content: ({ column }) => {
										return (
											<div className="d-flex align-items-center w-100">
												{column?.key !== "_id" && <CustomIcon className="dragColumn me-1" icon="drag" size={13} color="rgb(139, 141, 148)" />}
												<span>{column.title}</span>
											</div>
										);
									},
								},
								loading: {
									content: () => {
										return (
											<BaySpinner />
										)
									}
								},
								cellText: {
									content: (props) => {
										switch (props.column.key) {
											case '_id':
												return <div className="d-flex w-100 justify-content-between">
													<div className="bay-service-cell cursor-pointer align-items-center d-flex" style={{ height: '55px' }}>
														<div className="content services-text text-capitalize">
															{props?.rowData?._id}
														</div>
													</div>
													<div className="d-flex">
														<div className="vr"></div>
														<div className="px-2 d-flex align-items-center dot">
															<div className="col-action-button text-primary cursor-pointer dot py-1">
																<MlsQuickActions
																	handleActionClicked={handleActionClicked}
																	tableProps={tableProps}
																	setEnableActionModal={setEnableActionModal}
																	rowData={props.rowData}
																	dispatch={dispatch}

																/>
															</div>
														</div>
													</div>
												</div>

											case 'iconUrl':
												return <div style={{ width: '200px', wordWrap: 'break-word' }}>
													{props?.rowData?.iconUrl}
												</div>

											case 'ClientBAY':
											case 'SocialBAY':
												return props?.rowData?.targets[props.column.key]?.key ?
													<div>{props?.rowData?.targets[props.column.key]?.key} </div> :
													<div style={{ color: 'red' }}>Not Connected</div>

											case 'companyIds':
												return <div>
													<span className="text-decoration-underline cursor-pointer text text-primary" onClick={() => { setCustomerModalValue({ ...customerModalValue, data: [props?.rowData]}); setViewOpenModal(true) }}>View</span>
												</div>
											default:
												break;
										}
									}
								},
								sortIcon: {
									content: ({ column }) => {
										return (
											<span
												style={{
													width: 12,
													position: 'relative',
													top: 1,
													paddingRight: '0.5rem',
												}}
											>
												{column.sortDirection === SortDirection.Ascend ? (
													<CustomIcon
														className="cursor-pointer"
														icon="sortUp"
														size={16} color="#5d5f69"
													/>
												) : (
													<CustomIcon
														className="cursor-pointer"
														icon="sortDown"
														size={16}
														color="#5d5f69" />
												)}
											</span>
										);
									},
								},
							}}
						/>}
					</div>
				</div>
			</div>
		</div>
	)
}

export default MLSTable