export const DOMAIN_HEADER = 'Here you can enter a domain to get it setup with DNS on Rackspace and Certificates on HA Proxy.'

export const DOMAIN_OPTIONS = [
  {
    label: 'Site Type..',
    value: ''
  },
  {
    label: 'Broker',
    value: 'Broker'
  },
  {
    label: 'Team',
    value: 'Team'
  },
  {
    label: 'Agent',
    value: 'Agent'
  },
  {
    label: 'Office',
    value: 'Office'
  }
]
