import React, { useState, useRef, useMemo } from 'react';

import Overlay from 'react-bootstrap/Overlay';
import Dropdown from 'react-bootstrap/Dropdown';

import CustomIcon from 'components/Icons';
import { MLS_QUICK_ACTIONS_ARR } from 'components/Constants/mlsConstants';
import globalState from 'state/global';

export default function MlsQuickActions({
    rowData,
    setEnableActionModal,
}) {
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const globalDispatch = globalState.useDispatch();
    const mlsStateValue = globalState.useMLsState();
    const { } = mlsStateValue
    const handleActionClicked = (eventKey, event, rowData) => {
        event.stopPropagation();
        event.preventDefault();
        setEnableActionModal(true);
        globalDispatch(globalState.setSelectedAction({ eventType: eventKey, selectedActionRow: rowData?.mlsid, wholeRow: rowData }));

        setShow(false);
    }

    const quickActionsArr = useMemo(() => {
        if (rowData?.vendor !== "trestle") {
            return MLS_QUICK_ACTIONS_ARR.filter((item) => item?.value !== "generateTrestleReport")
        }
        return MLS_QUICK_ACTIONS_ARR
    }, [show])
    return (
        <div>
            <div className="px-2 d-flex align-items-center dot" onClick={() => { setShow(!show) }}>
                <div className={`col-action-button text-primary cursor-pointer dot ${show ? 'bay-icon-editable active' : ''}`} ref={target}>
                    <CustomIcon icon="vertical-ellipsis" size={16} />
                </div>
            </div>
            <Overlay target={target.current} show={show} placement="right-start" onHide={() => setShow(false)} rootClose={true}>
                {({ placement, arrowProps, show: _show, popper, ...props }) => {
                    return <div {...props} style={{ zIndex: 10, ...props.style, top: 30, left: -170 }}>
                        <Dropdown autoClose align="right-start" show={true} id={'row_dropdown_' + rowData?.testimonialId} className="d-flex">
                            <Dropdown.Menu show={true}>
                                {[...quickActionsArr].map((option, index) => {
                                    return <Dropdown.Item key={index} as={'span'} className="cursor-pointer my-2" eventKey="edit"
                                        onClick={(event) => { handleActionClicked(`${option.value}`, event, rowData) }}
                                        disabled={option.disabled}
                                    >
                                        {option.icon} {" "}{" "}
                                        {option.label}
                                    </Dropdown.Item>
                                })}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                }}
            </Overlay>
        </div>
    )
}
