import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Stack, Navbar, Nav, Button, Offcanvas, ToggleButtonGroup, ToggleButton, ButtonGroup, Modal, Form, Table, Card, FormCheck } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { isNil, cloneDeep, clone, uniqBy } from 'lodash';

import { PageContainer } from '../partials/page/PageContainer';
import { MainNav } from '../partials/navigation/MainNav';
import { PageHeader } from '../partials/headers/PageHeader';
import { Loading } from '../partials/loading/Loading';

import { FiPlus, FiEdit, FiTrash } from 'react-icons/fi';

import { Brand } from "../partials/input/Brand";
import { Roles } from "../partials/input/Roles";
import { Addresses } from "../partials/input/Addresses";
import { PhoneNumbers } from "../partials/input/PhoneNumbers";
import { getAccess } from "../../modules/access";

import api from '../../modules/api';

export const Users = (props) => {
	const [users, setUsers] = useState();
	const [user, setUser] = useState();

	const [userLoading, setUserLoading] = useState(false);
	const [isSaving, setIsSaving] = useState(false);

	useEffect(() => {
		handleRead();
	}, []);


	const handleRead = async (userId) => {
		setUsers();

		api.users.read(userId)
			.then(resp => {
				if(resp.status === 0){
					setUsers(resp.result);
				}else{
					console.error(resp.message);
					setUsers([]);
				}
			})
			.catch(e => {
				console.error(e.message);

				setUsers([]);
			});
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		setIsSaving(true);

		try {
			setIsSaving(false);
			setUser();
		} catch (error) {
			setIsSaving(false);
			setUser();
			console.error('Error updating user:', error);
		}
	};

	const handleChange = (event) => {
		const { name, value } = event.target;

		setUser((prevUser) => ({
			...prevUser,
			[name]: value,
		}));
	};

	const handleDelete = async (id) => {
		try {
			setUser({});
		} catch (error) {
			console.error('Error deleting user:', error);
			setUser();
		}
	};

	const handleCreate = async () => {
		try {
			setUser({});
		} catch (error) {
			console.error('Error creating user:', error);
			setUser();
		}
	};

	const handleSelect = async (user) => {
		try {
			setUser(user);
		} catch (error) {
			console.error('Error updating user:', error);
			setUser();
		}
	};

	return (
		<>
		<PageContainer id="index">
			<Row className='m-0'>
				<Col sm={2} className="bg-gray-200" style={{minHeight: 'calc(100vh - 60px)'}}>
					<MainNav />
				</Col>
				<Col sm={10} className="p-0 bg-gray-100" style={{minHeight: 'calc(100vh - 60px)'}}>
					<Container className="pt-3" fluid={true}>
						<PageHeader title="Users">
							<Stack className="align-self-center" direction="horizontal" gap={3}>
								{
									getAccess().isSuperAdmin || getAccess().isSystemAdmin
									? <div className="ms-auto"><Button variant="primary" size="sm" onClick={() => handleCreate()}><FiPlus /> Create User</Button></div>
									: null
								}
							</Stack>
						</PageHeader>
					</Container>
					<Container className="pt-3" fluid={true}>
						{isNil(users)
						? (
							<Loading message="Loading Users" />
						)
						: (
							<Table className="striped">
								<thead>
									<tr>
										<th>Username</th>
										<th>Full Name</th>
										<th>Email</th>
										<th>Phone Number</th>
										<th className="text-center">Action</th>
									</tr>
								</thead>
								<tbody>
									{users.map((user, index) => (
										<tr key={index}>
											<td>{user.username}</td>
											<td>{user.fullName}</td>
											<td>{user.email}</td>
											<td>{!isNil(user.phoneNumbers && user.phoneNumbers.length !== 0) ? user.phoneNumbers[0].number : ''}</td>
											<td className="d-flex align-items-center justify-content-center">
												<Stack direction="horizontal" gap={1}>
													<Button variant="link" className="p-0" size="sm" onClick={() => handleSelect(user)}><FiEdit /></Button>
													<Button variant="link" size="sm" onClick={() => handleDelete(user)}><FiTrash /></Button>
												</Stack>
											</td>
										</tr>
									))}
								</tbody>
							</Table>
						)}
					</Container>
				</Col>
			</Row>
		</PageContainer>

		<Offcanvas show={!isNil(user)} onHide={() => setUser()} placement="end" className="text-dark" backdrop={true} scroll={true}>
			<Offcanvas.Header closeButton>
				<Offcanvas.Title>User</Offcanvas.Title>
			</Offcanvas.Header>
			<Offcanvas.Body>
				{!isNil(user)  && (
					<Form onSubmit={handleSubmit}>
						<Form.Group className="mb-3">
							<Form.Label>Username:</Form.Label>
							<Form.Control type="text" name="username" value={user.username || ''} onChange={handleChange} />
						</Form.Group>

						<Form.Group className="mb-3">
							<Form.Label>Email:</Form.Label>
							<Form.Control type="email" name="email" value={user.email || ''} onChange={handleChange} />
						</Form.Group>

						<Form.Group className="mb-3">
							<Form.Label>First Name:</Form.Label>
							<Form.Control type="text" name="firstName" value={user.firstName || ''} onChange={handleChange} />
						</Form.Group>

						<Form.Group className="mb-3">
							<Form.Label>Last Name:</Form.Label>
							<Form.Control type="text" name="lastName" value={user.lastName || ''} onChange={handleChange} />
						</Form.Group>

						<Form.Group className="mb-3">
							<Form.Label>Birth Date:</Form.Label>
							<Form.Control type="date" name="birthDate" value={user.birthDate || ''} onChange={handleChange} />
						</Form.Group>

						<Form.Group className="mb-3">
							<Form.Label>License Number:</Form.Label>
							<Form.Control type="text" name="licenseNumber" value={user.licenseNumber || ''} onChange={handleChange} />
						</Form.Group>

						<Form.Group className="mb-3">
							<Form.Label>Certifications:</Form.Label>
							<Form.Control type="text" name="certifications" value={user.certifications || ''} onChange={handleChange} />
						</Form.Group>

						<Form.Group className="mb-3">
							<Form.Label>Website URL:</Form.Label>
							<Form.Control type="url" name="websiteUrl" value={user.websiteUrl || ''} onChange={handleChange} />
						</Form.Group>

						<Form.Group className="mb-3">
							<Form.Label>Company Name:</Form.Label>
							<Form.Control type="text" name="companyName" value={user.companyName || ''} onChange={handleChange} />
						</Form.Group>

						<Form.Group className="mb-3">
							<Form.Label>Time Zone:</Form.Label>
							<Form.Control type="text" name="timeZone" value={user.timeZone || ''} onChange={handleChange} />
						</Form.Group>

						<Addresses onChange={handleChange} addresses={user.addresses}/>

						<PhoneNumbers onChange={handleChange} phoneNumbers={user.phoneNumbers}/>

						<Brand onChange={handleChange} brand={user.brand}/>

						<Roles onChange={handleChange} roles={user.purview}/>

						<Form.Group className="mb-3">
							<Button variant="primary" type="submit" disabled={userLoading || isSaving}>Save</Button>
						</Form.Group>
					</Form>
				)}
			</Offcanvas.Body>
		</Offcanvas>
		</>
	);
};
