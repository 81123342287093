import axios from 'axios';
import { TOOLBOX_API_URL, LISTING_API_URL } from '../../lib/environment';
import Cookies from 'js-cookie'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	getViewConfig: (data, params) => {
		return new Promise((resolve, reject) => {
			// const bayToken = Cookies.get('bayToken');
			axios({
				url: `${TOOLBOX_API_URL}/mls/${params}${params?.length ? '?mlsid=' + params : ''}`,
				method: 'GET',
				cache: 'no-cache',
				data: data
				, signal: AbortSignal.timeout(180000)
			})
				.then((resp) => {
					resolve(resp.data.result);
				})
				.catch((err) => {


					reject(err);
				});
		});
	},
	insertDuplicateMls: (data) => {
		return new Promise((resolve, reject) => {
			axios({
				url: `${TOOLBOX_API_URL}/mls`,
				method: 'POST',
				cache: 'no-cache',
				data: data,
				signal: AbortSignal.timeout(180000)
			})
				.then((resp) => {
					resolve(resp.data.result);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},



	getMls: (data) => {
		return new Promise((resolve, reject) => {
			// const bayToken = Cookies.get('bayToken');
			axios({
				url: `${TOOLBOX_API_URL}/mls`,
				method: 'GET',
				cache: 'no-cache',
				data: data
				, signal: AbortSignal.timeout(180000)
			})
				.then((resp) => {
					resolve(resp.data.result);
				})
				.catch((err) => {


					reject(err);
				});
		});
	},

	trestleReport: (data) => {
		return new Promise((resolve, reject) => {
			const bayToken = Cookies.get('bayToken');
			let feedType = 'trestle';
			/* need to handle the feed type in dynamic way in future once after we got different feed types */
			axios({
				url: `${LISTING_API_URL}/feed/${feedType}/${data}/report`,
				method: 'POST',
				cache: 'no-cache',
				data: {},
				headers: {
					'Authorization': `Bearer ${bayToken}`
				},
				signal: AbortSignal.timeout(180000)
			})
				.then((resp) => {
					resolve(resp.data.result);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	getMlsStats: (data) => {
		return new Promise((resolve, reject) => {
			// const bayToken = Cookies.get('bayToken');


			axios({
				url: `${TOOLBOX_API_URL}/mls/AGG/stats`,
				method: 'GET',
				cache: 'no-cache',
				data: data,
				signal: AbortSignal.timeout(5000)
			})
				.then((resp) => {
					resolve(resp.data.result);
				})
				.catch((err) => {

					reject(err);
				});
		});
	},
};
