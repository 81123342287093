import { combineReducers } from 'redux'

import nav from '../slices/navSlice'
import notification from '../slices/notificationSlice'
import user from '../slices/userSlice'
import mls from '../slices/mlsSlice'
import actingAs from '../slices/actingAsSlice'
import home from '../slices/homeSlice'

export default combineReducers({
	nav,
	notification,
	user,
	actingAs,
	home,
	mls
});
