import React, { useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { hideColumn, showColumn } from 'ka-table/actionCreators';

import CustomIcon from 'components/Icons';

const getOverlayContent = (props, tableProps, dispatch, isLoading, target) => {
	const data = tableProps.columns.map(column => ({ ...column, visible: column.visible !== false }))

	const onSettingChange = (item) => {
		dispatch(
			item.target.checked
				? showColumn(item.target.id)
				: hideColumn(item.target.id)
		);
	}

	return (
		<div className='bg-white rounded shadow p-4' {...props} style={{ ...props.style, width: 256, zIndex: 98 }} >
			<div className='mb-1 show-hide'>Hide / Show Columns</div>
			<Form className={`${isLoading ? 'bay-placeholder-overlay' : ''}`} style={{ maxHeight: 320, overflowY: 'scroll' }}>
				{
					data.filter(e => (e.key !== 'selection-cell' && e.key !== 'name')).map((column, index) => {
						return (
							<Form.Check
								key={index}
								type="switch"
								id={column.key}
								className='mt-3 column-switch'
								label={<span className='ms-2 text-capitalize cursor-pointer'>{column.title.toLowerCase()}</span>}
								checked={column.visible}
								onChange={(item) => onSettingChange(item, target)}
							/>
						)
					})
				}
			</Form>
		</div>
	);
}

const VisibilityColumnComponent = ({ tableProps, dispatch, isLoading }) => {

	const target = useRef(null);

	return <OverlayTrigger
		trigger='click'
		placement='right-start'
		rootClose
		container={target.current}
		overlay={(props) => getOverlayContent(props, tableProps, dispatch, isLoading, target)}
	>
		<Button variant="outline-secondary" className='filter-btn d-flex align-items-center' disabled={isLoading}>
			<CustomIcon icon="column-section" className="me-md-2" /><span className='d-none d-md-inline'>Columns</span>
		</Button>
	</OverlayTrigger>
}

export default VisibilityColumnComponent;
