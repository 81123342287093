import React, { useState, useEffect, useMemo } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { kaReducer } from 'ka-table';
import BayHeader from '@back-at-you-inc/bay-ui/dist/BayHeader';
import BayToast from '@back-at-you-inc/bay-ui/dist/BayToast';

import api from '../../modules/api';
import { PageContainer } from '../partials/page/PageContainer';
import { MainNav } from '../partials/navigation/MainNav';
import MLSTable from './mls/UIComponents/mlsTable';
import DataGridActionsBar from './services/UIComponents/dataGridActionsBar';
import { DRAWER_FILTER_KEYS, MLS_CUSTOMER_MODAL_COLS, MLS_HEADER, MLS_STAT_TITLES, MLS_TABLEPROPS_INIT } from 'components/Constants/mlsConstants';
import globalState from 'state/global';
import MlsModalContent from './mls/UIComponents/mlsModalContent';
import MlsDrawer from './mls/UIComponents/mlsDrawer';
import MobileGridComponent from './mls/UIComponents/mobileGridComponent';
import StatsSection from './commonComponents/statsSection';
import MlsCustomerViewModal from './mls/UIComponents/mlsBayModal';

export const MLS = () => {
    const globalDispatch = globalState.useDispatch();
    const mlsStateValue = globalState.useMLsState();
    const { mlsStatDetail, mlsLists, toastContainer } = mlsStateValue
    const [filterTableProps, changeFilterTableProps] = useState(MLS_TABLEPROPS_INIT);
    const [tableProps, changeTableProps] = useState(MLS_TABLEPROPS_INIT);
    const [filterDrawerData, setFilterDrawerData] = useState({});
    const [showFilterDrawer, setShowFilterDrawer] = useState(false);
    const [enableActionModal, setEnableActionModal] = useState(false);
    const [searchValue, setSearchValue] = useState("");

    const [loading, setLoading] = useState(false);
    const [listLoading, setListLoading] = useState(false);
    const [statsLoading, setStatsLoading] = useState(false);

    const [viewOpenModal, setViewOpenModal] = useState(false)
    const [customerModalValue, setCustomerModalValue] = useState(MLS_CUSTOMER_MODAL_COLS);

    useEffect(() => {
        setLoading(true);
        setStatsLoading(true);
        api.mls.getMls()
        api.mls.getMlsStats()
            .then((res) => {
                globalDispatch(globalState.setMlsStatistics(res));
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setStatsLoading(false)
            });

        getServiceList();
    }, [])

    const getServiceList = () => {
        setListLoading(true)
        api.mls.getMls()
            .then((res) => {
                globalDispatch(globalState.setMlsLists(res));
                changeTableProps((prev) => ({ ...prev, data: res, isDataSet: true }))
                changeFilterTableProps((prev) => ({ ...prev, data: res, isDataSet: true }))
                setLoading(false)

            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setListLoading(false)
            });
    }

    const filteredTableProps = useMemo(() => {
        if (!searchValue) {
            return tableProps?.data
        } else {
            const filteredData = tableProps?.data?.filter(item => {
                return item?._id?.toString()?.toLowerCase().includes(searchValue?.toLowerCase()) ||
                    item?.name?.toString()?.toLowerCase().includes(searchValue?.toLowerCase()) ||
                    item?.iconUrl?.toLowerCase().includes(searchValue?.toLowerCase()) ||
                    item?.targets?.ClientBAY?.key?.toLowerCase().includes(searchValue?.toLowerCase()) ||
                    item?.targets?.SocialBAY?.key?.toString()?.toLowerCase().includes(searchValue) ||
                    item?.dtLastDownload?.toLowerCase().includes(searchValue?.toLowerCase()) ||
                    item?.dtLastDownloadFull?.toLowerCase().includes(searchValue?.toLowerCase()) ||
                    item?.dataServer?.toLowerCase().includes(searchValue?.toLowerCase()) ||
                    item?.dmServer?.toLowerCase().includes(searchValue?.toLowerCase())
            }
            );
            return filteredData
        }
        /* to search the results based on the input search */
    }, [searchValue, tableProps])

    const loopFilterData = (arr, item) => {
        return DRAWER_FILTER_KEYS.every((val) => (!arr[val]?.length || arr[val]?.includes('all') || arr[val]?.includes(item[val]?.toString().toLowerCase())))
        // to filter the drawer values 
    }

    const drawerFilterData = useMemo(() => {
        return filterTableProps?.data?.filter((item) => {
            return loopFilterData(filterDrawerData, item)
        })
    }, [filterDrawerData])

    useEffect(() => {
        changeTableProps((prev) => ({ ...prev, data: drawerFilterData }));
        CloseDrawer()
    }, [drawerFilterData])

    useEffect(() => {
        let count = 0;
        for (const appliedFilter of Object.values(filterDrawerData)) {
            if (appliedFilter.length) {
                count++;
            }
        }
        globalDispatch(globalState.setFilterCount(count));
        // to count the total number of filters applied
    }, [filterDrawerData])


    const dispatch = async (action) => {
        // let settingsWithoutDataObject = {};
        changeTableProps((prevState) => {
            const newState = kaReducer(prevState, action);
            return newState;
        });
    };


    const CloseDrawer = () => {
        setShowFilterDrawer(false)
    }

    const clearDrawerData = () => {
        changeTableProps({ ...tableProps, data: filterTableProps?.data })
        globalDispatch(globalState.setMainFilterData({}));
        globalDispatch(globalState.setFilterCount(0));
    }

    return (
        <div className="mls-page">
            <PageContainer id="index">
                <Row className='m-0'>
                    <Col sm={2} className="bg-gray-200" style={{ minHeight: 'calc(100vh - 60px)' }}>
                        <MainNav />
                    </Col>
                    <Col sm={10} className="p-0 bg-gray-100" style={{ minHeight: 'calc(100vh - 60px)' }}>
                        <Container className="pt-3" fluid={true}>
                            <BayHeader
                                headerContent={MLS_HEADER}
                                headerTitle={'Feeds'}
                            />
                        </Container>
                        <StatsSection
                            fluid={true}
                            statsList={mlsStatDetail}
                            statsLoading={statsLoading}
                            statsTitles={MLS_STAT_TITLES}
                        />
                        <Container fluid={true}>
                            <DataGridActionsBar
                                dispatch={dispatch}
                                loading={loading}
                                tableProps={tableProps}
                                SetEnableFilterDrawer={setShowFilterDrawer}
                                enableBulkAction={false}
                                enableVisibilityComponent={true}
                                setSearchValue={setSearchValue}
                            />
                        </Container>
                        <Container className="pt-2 pb-4" fluid={true}>
                            <div className='mls-table'>
                                <div className='bay-data-grid'>
                                    <div className="d-none d-sm-block">
                                        <MLSTable
                                            tableProps={{ ...tableProps, data: filteredTableProps }}
                                            dispatch={dispatch}
                                            loading={loading}
                                            listLoad={listLoading}
                                            services={mlsLists}
                                            setEnableActionModal={setEnableActionModal}
                                            setViewOpenModal={setViewOpenModal}
                                            customerModalValue={customerModalValue}
                                            setCustomerModalValue={setCustomerModalValue}
                                        />
                                    </div>
                                    <MobileGridComponent
                                        filteredTableProps={filteredTableProps}
                                        loading={loading}
                                    />
                                </div>
                            </div>
                        </Container>
                        <MlsDrawer
                            CloseDrawer={CloseDrawer}
                            clearDrawerData={clearDrawerData}
                            showFilterDrawer={showFilterDrawer}
                            setFilterDrawerData={setFilterDrawerData}
                        />
                    </Col>
                </Row>
                <MlsModalContent
                    enableActionModal={enableActionModal}
                    setEnableActionModal={setEnableActionModal}
                />
                <MlsCustomerViewModal
                    loading={loading}
                    viewOpenModal={viewOpenModal}
                    setViewOpenModal={setViewOpenModal}
                    modalHeader={'Customers'}
                    customerModalValue={customerModalValue}
                />
                <BayToast variant={toastContainer?.variant} text={toastContainer?.text} show={toastContainer?.show} onClose={()=> globalDispatch(globalState.setToastContainer({show:false}))}/>
            </PageContainer>
        </div>
    )
}
