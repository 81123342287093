import React, { useEffect, useState, useRef } from "react";
import { Col, Row, Form, InputGroup, Button } from "react-bootstrap";
import * as Yup from 'yup';
import { Formik } from "formik";

import BaySelect from '@back-at-you-inc/bay-ui/dist/BaySelect';
import BayModal from '@back-at-you-inc/bay-ui/dist/BayModal';

import { DomainCard } from "./domainCard";
import { DOMAIN_OPTIONS } from "components/Constants/domainConstants";
import api from "modules/api";

const schema = Yup.object().shape({
	domain: Yup.string()
		.required('Please enter a domainname')
		.matches(
			/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g,
			'Invalid domain format'
		),
	baySelect: Yup.string()
		.required('Site Type is required')
});

export const DomainMainContent = () => {

	const [openModal, setOpenModal] = useState(false);
	const [myId, setMyId] = useState('')
	const [displayLoop, setDisplayLoop] = useState({})
	const intervalRef = useRef(null);

	useEffect(() => {
		if (myId?.length) {
			intervalRef.current = setInterval(async () => {
				let newData = await progressDomain(myId)
				setDisplayLoop(newData)
			}, 5000)
		}
	}, [myId])

	useEffect(() => {
		if (!openModal) {
			setMyId("")
			clearInterval(intervalRef.current);
			setDisplayLoop({})
		}
	}, [openModal])

	const fetchSiteType = (url) => {
		api.domain.getSiteType(url)
			.then((res) => {
			})
			.catch((err) => {
				console.log(err);
			})
	}
	const progressDomain = (idData) => {
		return api.domain.getIdInfoProgress
		(idData).then((ress) => ress)
	}
	const createDomain = (domain, url) => {
		api.domain.domain(domain, url)
			.then((res) => {
				if (res.status === 0) {
					setMyId(res?.result?._id)
						.catch((err) => {
							console.log(err);
						})
				}
			})
			.catch((err) => {
				console.log(err);
			})
	}

	const onSubmit = (e, values) => {
		e.preventDefault();
		let url = '';
		const { domain, baySelect } = values
		if (baySelect !== DOMAIN_OPTIONS[0].value) {

			if (baySelect === "Office") {
				url = "clientBay"
			} else if (baySelect === "Agent") {
				url = 'sitesBay.agent'
			} else {
				url = 'sitesBay.broker'
			}
			setOpenModal(true);
			createDomain(domain, url);
		}
	};
	return (
		<div>
			<Row className='m-0'>
				<Col xl={9} lg={9} md={12} xs={12} sm={12}>
					<Row>
						<Formik
							validationSchema={schema}
							initialValues={{ domain: '', baySelect: DOMAIN_OPTIONS[0].value }}
							validateOnBlur
							validateOnChange
						>
							{({
								values,
								handleChange,
								setFieldValue,
								dirty,
								errors,
							}) => (
								<Form onSubmit={(e) => onSubmit(e, values)}>
									<Row>
										<Col xs={12} sm={12} md={4} xl={7} lg={7} >
											<Form.Group>
												<InputGroup className="my-2">
													<InputGroup.Text id="basic-addon1">www.</InputGroup.Text>
													<Form.Control
														id="domain"
														name="domain"
														placeholder="Enter Domain Name"
														onChange={handleChange}
													/>
												</InputGroup>
												<Form.Control.Feedback className="d-flex fs-6" type="invalid">
													{errors.domain}
												</Form.Control.Feedback>
											</Form.Group>
										</Col>
										<Col className="my-2" xs={12} sm={12} md={4} xl={2} lg={3} >
											<Form.Group>
												<BaySelect
													id="baySelect"
													name="baySelect"
													className="baySelect"
													optionsArray={DOMAIN_OPTIONS}
													onChangeFunc={(selected) => setFieldValue('baySelect', selected?.value || '')}
													hideSelectedOne={false}
													isClearable={false}
													placeholder="Site Type.."
												/>
												<Form.Control.Feedback className="d-flex fs-6" type="invalid">
													{errors.baySelect}
												</Form.Control.Feedback>
											</Form.Group>
										</Col>
										<Col className="my-2" xs={12} sm={12} md={4} xl={3} lg={3}>
											<Button
												name='saveButton'
												variant='primary'
												type='submit'
												className="w-100 p-2"
												disabled={!dirty || Object.values(values).some(value => value === '' || value == null)}
											>
												Create Domain
											</Button>	
										</Col>
									</Row>
								</Form>
							)}
						</Formik>
					</Row>
				</Col>
			</Row>

			{openModal && <BayModal show={openModal} onHideFunc={() => { setOpenModal(false); setMyId("") }} closeButton={true} buttonText="Ok" submitFunc={() => { setOpenModal(false); setMyId("")  }} cancelText="Cancel">
				<Row>
					<DomainCard displayLoop={displayLoop}/>
				</Row>
			</BayModal>}
		</div>
	)
}