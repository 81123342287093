import CustomIcon from 'components/Icons'
import React from 'react'

export default function CustomInput({ setSearchValue, showIcon = true, fieldLabel = "", validate = false, isRequired = false }) {
    return (
        <div className='custom-modal-content row d-flex align-items-center'>
            {fieldLabel && <label className='modal-label mb-0 col-3 col-sm-3 col-md-3 col-lg-3'> {fieldLabel} {isRequired ? <span className='text-danger'>*</span> : ''}</label>}
            <div className="col-9 col-sm-9 col-md-9 col-lg-9 d-flex align-items-center justify-content-end">
                <div className={`services-search-container ${validate ? 'enableValidate' : ''}`}>
                    {showIcon && <div className="search-icon">
                        <CustomIcon icon="search" size={16} />
                    </div>}
                    <input
                        className={`search-input `}
                        placeholder='Search...'
                        onChange={(e) => {
                            setSearchValue(e.target.value)
                        }}
                    />
                </div>
            </div>
        </div>
    )
}
