import React from 'react';
import { Form, Button } from 'react-bootstrap';
import { uniqBy } from 'lodash';

import BaySelect from '@back-at-you-inc/bay-ui/dist/BaySelect';
import globalState from 'state/global';

const ServiceFilterFormCommon = ({
    handleApplyDrawerFilter,
    clearDrawerFilter,
    fieldArr,
    formId = ""
}) => {
    const globalDispatch = globalState.useDispatch();
    const mlsStateValue = globalState.useMLsState();
    const { mainFilterData } = mlsStateValue
    const handleApplyFilter = () => {
        handleApplyDrawerFilter()
    }
    const handleOnFilterChange = (selectedValue, val) => {
        const { name } = val;
        globalDispatch(globalState.setMainFilterData({
            ...mainFilterData,
            [name]: selectedValue,
        }));
        ;
    }

    return (
        <div>
            <Form id={formId}>
                {fieldArr?.length && fieldArr?.map((item, index) => {
                    return <Form.Group key={index} className="py-2">
                        <Form.Label>{item.title}</Form.Label>
                        <BaySelect
                            onChangeFunc={handleOnFilterChange}
                            name={item.name}
                            isMulti={item.isMulti}
                            isClearable={item.isClearable}
                            optionsArray={
                                uniqBy(item.optionsArr, item.name)?.map((e, i) => {
                                    return {
                                        label: e[item.name],
                                        value: e[item.name]
                                    };
                                })}
                            defaultValue={item.defaultValue[item.name]}
                            className="filter-dropdown"
                        />

                    </Form.Group>
                })}
                <div className='border-top p-3 mt-2'>
                    <div className="d-flex align-items-center justify-content-between">
                        <Form.Group className="form-item">
                            <Button
                                variant="link"
                                className="me-4 btn-clear"
                                onClick={() => clearDrawerFilter()}
                            >
                                Clear All
                            </Button>
                        </Form.Group>
                        <Form.Group className="form-item d-flex align-items-center">
                            <Button
                                variant="primary"
                                className="btn-filter"
                                onClick={() => handleApplyFilter()}
                            >
                                Apply Filter
                            </Button>
                        </Form.Group>
                    </div>
                </div>
            </Form>
        </div>
    )
}

export default ServiceFilterFormCommon
